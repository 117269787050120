import React, { useEffect, useState } from 'react';
import Button from 'antd/lib/button/button';
import { Modal, Icon } from 'antd';
import { withFormik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';

import { InputField } from '../../../common/components';
import './editAdditionIngridient.global.scss';
import { UpdateAdditionIngredientValues, Ingridient } from '../../types';
import { ingridientStore } from '../../stores';

interface EditIngridientsOwnProps {
  ingridient: Ingridient;
}

const EditAdditionIngridients = (props: EditIngridientsOwnProps & FormikProps<UpdateAdditionIngredientValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue, ingridient } = props;
  const [visible, setVisible] = useState(false);
  const [moreActions, setMoreActions] = useState(values.moreActions || []);
  const showModal = () => setVisible(true);
  const handleOk = () => {
    handleSubmit();
    setVisible(false);
  };
  const handleCancel = () => setVisible(false);

  const addNewAction = () =>
    setMoreActions([
      ...moreActions,
      {
        actionName: '',
        actionValue: ''
      }
    ]);

  const renderMoreActions = () => {
    return moreActions.map(({ actionName, actionValue }, indexOf) => {
      return (
        <div key={indexOf}>
          <InputField
            title={'ActionName інгредієнта'}
            placeholder={'AdditionAsBase'}
            name={`moreActions[${indexOf}].actionName`}
            defaultValue={actionName}
            onBlur={() => setFieldTouched(`moreActions[${indexOf}].actionName`)}
            onChange={event => setFieldValue(`moreActions[${indexOf}].actionName`, event.target.value)}
          />
          <InputField
            title={'ActionValue інгредієнта'}
            placeholder={'default'}
            name={`moreActions[${indexOf}].actionValue`}
            defaultValue={actionValue}
            onBlur={() => setFieldTouched(`moreActions[${indexOf}].actionValue`)}
            onChange={event => setFieldValue(`moreActions[${indexOf}].actionValue`, event.target.value)}
          />
          <hr />
        </div>
      );
    });
  };

  useEffect(() => {
    renderMoreActions();
  }, [moreActions]);

  return (
    <div>
      <Button size="small" onMouseDown={showModal}>
        <Icon type="edit" />
      </Button>

      <Modal
        className="modalWrap"
        title="Додати переклад до інгредієнту"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <form>
          <InputField
            title={`Переклад інгредієнту на ${Storage('sessionStorage').get('language')}`}
            placeholder={'Мінімум 2, максимум 60 символів'}
            name="name"
            defaultValue={ingridient.name}
            value={values.name}
            onBlur={() => setFieldTouched('name')}
            errorMessage={touched.name && errors.name}
            onChange={event => setFieldValue('name', event.target.value)}
          />

          <InputField
            title={'Вартість інгредієнту'}
            placeholder={'Вартість'}
            name="price"
            defaultValue={ingridient.price}
            value={values.price}
            onBlur={() => setFieldTouched('price')}
            errorMessage={touched.price && errors.price}
            onChange={event => setFieldValue('price', event.target.value)}
          />
          {renderMoreActions()}
          <Button onClick={addNewAction}>Додати новий Action</Button>
        </form>
      </Modal>
    </div>
  );
};

const EditAdditionIngridientsWithFormik = withFormik<EditIngridientsOwnProps, UpdateAdditionIngredientValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ ingridient }) => ({
    url: ingridient.url,
    name: ingridient.name,
    price: ingridient.price,
    type: 'ADDITIONAL',
    moreActions: ingridient.moreActions
  }),
  handleSubmit: (values, { props: { ingridient } }) => {
    console.warn(ingridient, values);
    // alert(ingridient.specialActionName + ' ' + ingridient.specialActionValue);
    ingridientStore.update(
      ingridient.id,
      // { ...values, url: ingridient.url, specialActionName: ingridient.specialActionName.trim().length > 1 ? ingridient.specialActionName.trim() : null  , specialActionValue: ingridient.specialActionValue ? 'Default' : null },
      { ...values, url: ingridient.url },
      Storage('sessionStorage').get('language') || 'UK'
    );
  },
  validationSchema: yup.object().shape<any>({
    name: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(60, 'Назва повинна мати не більше 60 символів')
      .required('Це поле не може бути порожнім!')
  }),
  displayName: 'Add ingridients'
})(observer(EditAdditionIngridients));

export default EditAdditionIngridientsWithFormik;
