import React, { useEffect, useState } from 'react';
import Button from 'antd/lib/button/button';
import { Modal } from 'antd';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';

import { ingridientStore } from '../../stores';

import { InputField } from '../../../common/components';
import './addIngridients.global.scss';
import { AddIngredientsValue } from '../../types';

const AddIngridients = (props: any) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  const [visible, setVisible] = useState(false);
  const [moreActions, setMoreActions] = useState(values.moreActions);
  const showModal = () => setVisible(true);
  const handleOk = () => {
    handleSubmit();
    setVisible(false);
  };
  const handleCancel = () => setVisible(false);

  const addNewAction = () =>
    setMoreActions([
      ...moreActions,
      {
        actionName: '',
        actionValue: ''
      }
    ]);

  const renderMoreActions = () => {
    return moreActions.map(({ actionName, actionValue }, indexOf) => {
      return (
        <div key={indexOf}>
          <InputField
            title={'ActionName інгредієнта'}
            placeholder={'AdditionAsBase'}
            name={`moreActions[${indexOf}].actionName`}
            defaultValue={actionName}
            onBlur={() => setFieldTouched(`moreActions[${indexOf}].actionName`)}
            onChange={event => setFieldValue(`moreActions[${indexOf}].actionName`, event.target.value)}
          />
          <InputField
            title={'ActionValue інгредієнта'}
            placeholder={'default'}
            name={`moreActions[${indexOf}].actionValue`}
            defaultValue={actionValue}
            onBlur={() => setFieldTouched(`moreActions[${indexOf}].actionValue`)}
            onChange={event => setFieldValue(`moreActions[${indexOf}].actionValue`, event.target.value)}
          />
          <hr />
        </div>
      );
    });
  };

  useEffect(() => {
    renderMoreActions();
  }, [moreActions]);

  return (
    <div>
      <Button onMouseDown={showModal}>Додати новий</Button>
      <Modal
        className="modalWrap"
        title="Додати новий інгредієнт"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <form>
          <InputField
            title={'Назва інгредієнту'}
            placeholder={'Мінімум 2, максимум 60 символів'}
            name="name"
            value={values.name}
            onBlur={() => setFieldTouched('name')}
            errorMessage={touched.name && errors.name}
            onChange={event => setFieldValue('name', event.target.value)}
          />

          <InputField
            title={'Вартість інгредієнту'}
            placeholder={'Вартість'}
            name="price"
            defaultValue={values.price}
            onBlur={() => setFieldTouched('price')}
            errorMessage={touched.price && errors.price}
            onChange={event => setFieldValue('price', event.target.value)}
          />
          {renderMoreActions()}
          <Button onClick={addNewAction}>Додати новий Action</Button>
        </form>
      </Modal>
    </div>
  );
};

const AddIngridientsWithFormik = withFormik<any, AddIngredientsValue>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: '',
    price: 0,
    type: 'ADDITIONAL',
    moreActions: [
      {
        actionName: '',
        actionValue: ''
      }
    ]
  }),
  handleSubmit: (values, { resetForm }) => {
    ingridientStore.create(values);
    resetForm();
  },
  validationSchema: yup.object().shape<AddIngredientsValue>({
    name: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(60, 'Назва повинна мати не більше 60 символів')
      .required('Це поле не може бути порожнім!')
  }),
  displayName: 'Add ingridients'
})(observer(AddIngridients));

export default AddIngridientsWithFormik;
