import { notification, message } from 'antd';
import axios from 'axios';

import Storage from '../../common/utils/Storage';
import settings from '../../../settings';

import { AddIngredientsValue, Dish, UpdateAdditionIngredientValues } from '../types';

class Ingridients {
  public load = async () => {
    try {
      const { data } = await axios.get(
        `${settings.domain}/api/admin/ingredient/all?menuId=${Storage('sessionStorage').get('menu').id}`
      );
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити інгредієнти. Спробуйте пізніше.'
      });
      throw error;
    }
  };
  public create = async ({ name, type, price, moreActions }: AddIngredientsValue) => {
    const ingridientData = {
      type,
      name,
      price,
      remove: true,
      moreActions,
      menuId: Storage('sessionStorage').get('menu').id
    };

    try {
      const response = await axios.post(`${settings.domain}/api/admin/ingredient`, ingridientData);
      if (response.data.code !== 200) {
        notification.error({
          message: 'Не вдалося створити інгредієнт.',
          description: 'Такої страви не існує. Спробуйте іншу страву.'
        });
        return response.data.code;
      } else {
        message.success('Інгредієнт успішно створений!', 5);
        return response.data;
      }
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося створити інгредієнт. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public update = async (
    ingridientId: number,
    { name, type, price, url, moreActions }: UpdateAdditionIngredientValues,
    langType: string
  ) => {
    const ingridientData = {
      type,
      name,
      price,
      url,
      moreActions,
      remove: true
    };

    try {
      const { data } = await axios.put(`${settings.domain}/api/admin/ingredient/${ingridientId}`, ingridientData, {
        headers: { 'Accept-Language': langType }
      });
      message.success('Інгредієнт успішно оновлен!');
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося редагувати інгредієнт. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public delete = async (ingredientId: number) => {
    try {
      const { data } = await axios.delete(`${settings.domain}/api/admin/ingredient/${ingredientId}`);
      if (data.code !== 200) {
        notification.error({
          message: 'Не вдалося видалити інгредієнт.',
          description: 'Такої страви не існує. Спробуйте іншу страву.'
        });
        return data.code;
      } else {
        message.success('Інгредієнт успішно видалений!', 5);
        return data;
      }
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося видалити інгредієнт. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public addToDish = async (ingridientsId: number[], dish: Dish) => {
    try {
      const response = await axios.patch(`${settings.domain}/api/admin/menu-item/${dish.id}`, ingridientsId);
      if (response.data.code !== 200) {
        notification.error({
          message: 'Не вдалося додати інгредієнт до страви.',
          description: 'Такої страви не існує. Спробуйте іншу страву.'
        });
        return response.data.code;
      } else {
        message.success('Інгредієнт успішно доданий до страви!', 5);
        return response.data;
      }
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося додати інгредієнт до страви. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public removeFromDish = async (ingridientsId: number[], dish: Dish) => {
    try {
      const { data } = await axios.patch(`${settings.domain}/api/admin/menu-item/${dish.id}`, ingridientsId);
      if (data.code !== 200) {
        notification.error({
          message: 'Не вдалося видалити інгредієнт зі страви.',
          description: 'Такої страви не існує. Спробуйте іншу страву.'
        });
        return data.code;
      } else {
        message.success('Інгредієнт успішно видалений зі страви!', 5);
        return data;
      }
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося видалити інгредієнт зі страви. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new Ingridients();
