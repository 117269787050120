import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Switch } from 'antd';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';
import { InputField } from '../../../common/components';
import { UpdateWhereEat } from '../../types';
import { screenStore } from '../../stores';
import MenuLang from '../MenuLang';

import styles from './whereEatForm.module.scss';
import SharedBgImage from '../SharedBgImage';
// import OptionButton from '../../../common/components/OptionsButton';

const WhereEatForm = (props: FormikProps<UpdateWhereEat>) => {
  const [activeLang, setActiveLang] = useState(String(Storage('sessionStorage').get('language')).toLowerCase());
  const { handleSubmit, setFieldTouched, touched, errors, setFieldValue, values } = props;

  const handleLangChange = (language: string) => {
    setActiveLang(language.toLowerCase());
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Де поїсти?</h3>
      <div className={styles.wrap}>
        <div className={styles.fieldsWrap}>
          <InputField
            title={'Кнопка (У закладі)'}
            placeholder={'У закладі'}
            name="buttonShop"
            value={values.buttonShop[activeLang]}
            onBlur={() => setFieldTouched(`buttonShop.${activeLang}`)}
            errorMessage={
              touched.buttonShop && touched.buttonShop[activeLang] && errors.buttonShop && errors.buttonShop[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonShop.${activeLang}`, event.target.value);
              setFieldTouched(`buttonShop.${activeLang}`);
            }}
          />
          <InputField
            title={'Кнопка (На винoс)'}
            placeholder={'З собою'}
            name="buttonSelf"
            value={values.buttonSelf[activeLang]}
            onBlur={() => setFieldTouched(`buttonSelf.${activeLang}`)}
            errorMessage={
              touched.buttonSelf && touched.buttonSelf[activeLang] && errors.buttonSelf && errors.buttonSelf[activeLang]
            }
            onChange={event => {
              setFieldValue(`buttonSelf.${activeLang}`, event.target.value);
              setFieldTouched(`buttonSelf.${activeLang}`);
            }}
          />
          <SharedBgImage screenType="whereEat" />
        </div>
        <div className={styles.buttonsWrap}>
          <div className={styles.wrapTopButtons}>
            <MenuLang onChange={handleLangChange} />
            {/* TODO:here must be OptionButton */}
            {/* <OptionButton /> */}
          </div>
          <div className={styles.wrapBottomButtons}>
            <Switch
              defaultChecked={
                screenStore.screens && screenStore.screens.whereEat ? screenStore.screens.whereEat.switchOff : false
              }
              onChange={() =>
                screenStore.update(
                  {
                    ...(screenStore.screens && screenStore.screens.whereEat),
                    switchOff:
                      screenStore.screens && screenStore.screens.whereEat && !screenStore.screens.whereEat.switchOff
                  },
                  'whereEat'
                )
              }
            />
            <Button className={styles.saveButton} type="primary" htmlType="submit">
              Зберегти
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const WhereEatFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    imageUrl: screenStore.screens && screenStore.screens.whereEat && screenStore.screens.whereEat.background?.imageUrl,
    buttonShop: {
      en:
        screenStore.screens &&
        screenStore.screens.whereEat &&
        screenStore.screens.whereEat.buttonShop &&
        screenStore.screens.whereEat.buttonShop.name.en,
      pl:
        screenStore.screens &&
        screenStore.screens.whereEat &&
        screenStore.screens.whereEat.buttonShop &&
        screenStore.screens.whereEat.buttonShop.name.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.whereEat &&
        screenStore.screens.whereEat.buttonShop &&
        screenStore.screens.whereEat.buttonShop.name.ru,
      uk:
        screenStore.screens &&
        screenStore.screens.whereEat &&
        screenStore.screens.whereEat.buttonShop &&
        screenStore.screens.whereEat.buttonShop.name.uk
    },
    buttonSelf: {
      en:
        screenStore.screens &&
        screenStore.screens.whereEat &&
        screenStore.screens.whereEat.buttonSelf &&
        screenStore.screens.whereEat.buttonSelf.name.en,
      pl:
        screenStore.screens &&
        screenStore.screens.whereEat &&
        screenStore.screens.whereEat.buttonSelf &&
        screenStore.screens.whereEat.buttonSelf.name.pl,
      ru:
        screenStore.screens &&
        screenStore.screens.whereEat &&
        screenStore.screens.whereEat.buttonSelf &&
        screenStore.screens.whereEat.buttonSelf.name.ru,
      uk:
        screenStore.screens &&
        screenStore.screens.whereEat &&
        screenStore.screens.whereEat.buttonSelf &&
        screenStore.screens.whereEat.buttonSelf.name.uk
    }
  }),
  handleSubmit: ({ buttonShop, buttonSelf, imageUrl }) => {
    screenStore.update(
      {
        buttonShop: {
          description: screenStore.screens && screenStore.screens.whereEat && screenStore.screens.whereEat.buttonShop,
          name: {
            en: buttonShop.en,
            pl: buttonShop.pl,
            ru: buttonShop.ru,
            uk: buttonShop.uk
          }
        },
        buttonSelf: {
          description: screenStore.screens && screenStore.screens.whereEat && screenStore.screens.whereEat.buttonSelf,
          name: {
            en: buttonSelf.en,
            pl: buttonSelf.pl,
            ru: buttonSelf.ru,
            uk: buttonSelf.uk
          }
        },
        background: {
          imageUrl
        }
      },
      'whereEat'
    );
  },
  validationSchema: yup.object().shape<any>({
    buttonShop: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    }),
    buttonSelf: yup.object().shape({
      en: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      pl: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      ru: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!'),
      uk: yup
        .string()
        .min(2, 'Назва повинна мати не меньше 2 символів')
        .max(60, 'Назва повинна мати не більше 60 символів')
        .required('Це поле не може бути порожнім!')
    })
  }),
  displayName: 'WhereEatForm'
})(observer(WhereEatForm));

export default WhereEatFormWithFormik;
